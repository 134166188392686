html, body {
  width: 100%;
  height: 100%;
  background-color:black;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #656a72;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.caller {
  width: 100%;
  height: 40px;
  background-color: rgb(109, 109, 109);
  opacity: 0.5;
  position: absolute;
  z-index: 9995;
}

.callerNameContainer{
  width: 100%;
  height: 40px;
  position: absolute;
  z-index: 9995;
}

.callerName {
  margin: 0px auto;
  max-width: 200px;
  color: white;
  padding-top: 5px;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
}

.videoNavigation {
  position: absolute;
  width: 100%;
  z-index: 9995;
  bottom: 5%;
}

.stopwatch {
  position: absolute;
  z-index: 9999;
  color: black;
}

.buttonContainer {
  margin: 0px auto;
  max-width: 230px;
  align-items: center;
}

.OT_video-poster {
  position: static !important;
}

.vCbutton {
  padding: 5px;
  cursor: pointer;
  max-width: 50px;
  z-index: 9995;
}

.vVbutton {
  padding: 5px;
  cursor: pointer;
  max-width: 50px;
  z-index: 9995;
}

.eCbutton {
  padding: 0px;
  cursor: pointer;
  max-width: 70px;
  z-index: 9995;
}

.vCbutton, .eCbutton {
  margin-right: 20px;
}

.subscriberLayer {
  position: absolute;
  right: 0;
  z-index: 9995;
  margin-top: 70px;
  margin-right: 20px;
  border-radius: 8;
}

.btContinue {
  background-color: #3db55b;
  text-decoration: none;
  color: white;
  padding: 10px 45px;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
}

.errorContainer{
  position: absolute;
  width: 100%; 
  top: 0;
  background-color: red;
  z-index: 10000;
  padding: 10px;
  color: white;

  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

 @-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

.isLoading {
  position: absolute; 
  margin: 20% auto;
  width: 100%;
  text-align: center;
  z-index: 9998;
  color: white;
}

@media (max-width: 1200px) {
  .isLoading {
    margin: 30% auto;
  }
}

@media (max-width: 992px) {
  .isLoading {
    margin: 35% auto;
  }
}

@media (max-width: 768px) {
  .isLoading {
    margin: 45% auto !important;
  }
}

@media (max-width: 415px) {
  .isLoading {
    margin: 50% auto !important;
  }
}

@media (max-width: 400px) {
  .isLoading {
    margin: 55% auto !important;
  }
}

